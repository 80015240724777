<template>
  <div>
      <div class="container-sm">
      <div class="form-bg">

        <div class="form-container p-3 p-sm-5 pt-4 pb-4 pb-sm-5">
                  <div><h2 class="text-center fs-3 mb-4 mb-sm-5 fw-light">{{ $t('auth.title.login') }}</h2></div>

              <div class="login-card-img-div text-center" style="margin-bottom: 150px; margin-top: 150px;">
                <img style="width: 120px;" src="../../../assets/img/login-arrow-up.png" alt="">
              </div>

          <div class="fs-5 fw-light text-center">
          <p>{{ $t('auth.title.put_card_to_reader') }}</p>
          </div>
        </div>
        <div class="login-footer"><Footer></Footer></div>
      </div>
    </div>

  </div>
</template>

<script>
import { LOGIN_CARD, LOGOUT } from "@/store";
import i18n from "@/plugins/vue-i18n";
import Footer from "@/views/core/Footer";

export default {
  name: 'Login',
  data() {
    return {
      cardhex: '-'
    };
  },
  components: {
      Footer
  },
  mounted() {
    const that = this;
    const connection = new WebSocket(this.$websocketServer)
    connection.onmessage = function(cardhex) {
      that.cardhex = cardhex.data;
      that.processLogin();
    }
  },
  methods: {
    processLogin() {
     alert(this.cardhex);
     
      const cardhex = this.cardhex;

      this.$store.dispatch(LOGOUT);
      this.$store
        .dispatch(LOGIN_CARD, { cardhex })
        .then(response => {
          this.$toasted.success(i18n.t('message.' + response.message));
          this.$router.push({ name: 'orders_new' })
      });
    }
}
}
</script>