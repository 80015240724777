<template>
  <div>
    <div class="container-sm">
      <div class="form-bg">

        <div class="form-container p-2 p-sm-4 pt-3 pb-4 pb-sm-5">
          <div><h2 class="text-center fs-3 mb-4 mb-sm-5 fw-light">{{ $t('auth.title.login') }}</h2></div>

          <div
              v-if="errors && errors.length > 0" role="alert" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
              <div class="alert-text text-center" v-for="(error, i) in errors" :key="i">
                {{ $t('auth.' + error )}}
              </div>
           </div>

          <div class="input-div mb-4">
            <label for="input_first_name fw-light">{{ $t('auth.label.name') }}:</label><br />
            <input
              v-model="first_name"
              :placeholder="$t('auth.placeholder.first_name')"
              id="input_first_name"
              class="w-100 py-3 px-3 mt-1"
            />
          </div>

          <div class="input-div mb-4">
            <label for="input_last_name fw-light">{{ $t('auth.label.surname') }}</label><br />
            <input
              v-model="last_name"
              :placeholder="$t('auth.placeholder.last_name')"
              id="input_last_name"
              class="w-100 py-3 px-3 mt-1"
            />
          </div>

          <div class="input-div mb-4">
            <label for="card_number fw-light">{{ $t('auth.label.personal_number') }}:</label><br />
            <input
              v-model="card_number"
              :placeholder="$t('auth.placeholder.card_number')"
              id="card_number"
              class="w-100 py-3 px-3 mt-1"
            />
          </div>
          <div class="login-button-div">
          <button class="login-button px-5 py-3" v-on:click="this.onSubmit">{{ $t('auth.button.login') }}</button>
          </div>
        </div>
        <div class="login-footer"><Footer></Footer></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/store";
import i18n from "@/plugins/vue-i18n";
import Footer from "@/views/core/Footer";

export default {
  name: "Login",
  data() {
    return {
      first_name: process.env.NODE_ENV === "production" ? "" : "David",
      last_name: process.env.NODE_ENV === "production" ? "" : "Roško",
      card_number: process.env.NODE_ENV === "production" ? "" : "4096",
    };
  },
  components: {
      Footer
  },
  computed: {
    ...mapState({
      errors: state => state.errors
    })
  },
  methods: {
    onSubmit() {
      const first_name = this.first_name;
      const last_name = this.last_name;
      const card_number = this.card_number;

      // clear existing errors
      this.$store.dispatch(LOGOUT);
      this.$store
        .dispatch(LOGIN, { first_name, last_name, card_number })
        .then((response) => {
          this.$toasted.success(i18n.t("message." + response.message));
          this.$router.push({ name: "orders_new" });
        });
    },
  },
};


</script>