<template>
  <div>
    <Header></Header>
    <LoginPassword v-if="this.$terminalType == 'internet'"></LoginPassword>
    <LoginCard v-if="this.$terminalType == 'terminal'"></LoginCard> 
  </div>
</template>

<style lang="scss">
  // Module styles
  @import "assets/sass/style";
</style>

<script>
import i18n from "@/plugins/vue-i18n";
import Header from "@/views/core/Header";
import LoginPassword from "./views/LoginPassword";
import LoginCard from "./views/LoginCard";

export default {
  metaInfo () { 
      return { title: i18n.t("meta.login")} 
  },
  components: {
    Header, LoginPassword, LoginCard
  }
};
</script>